export function paramsWithRedirectTo(
  to: Nullable<string>,
  params = new URLSearchParams()
) {
  if (to) {
    // URLSearchParams will auto encode the url
    params.set('redirect-to', to);
  }
  return params;
}
